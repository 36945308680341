<!--  -->
<template>
  <div class="this-insurance-detail">
    <div class="head">
      <div class="top">
        <div
          class="backimage"
          @click="back()"
        >
          <img src="../../assets/image/heiback.png" />
        </div>
        <p style="color:#333">商品详情</p>
      </div>
    </div>
    <div class="cont">
      <div class="theSwpper">
        <van-swipe @change="onChange">
          <van-swipe-item

          >
             <!-- v-for="(item,index) in proctImgList" -->
          <img :src="flProductHomeImage" /></van-swipe-item>
          <!-- <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{proctImgList.length}}</div>
          </template> -->
        </van-swipe>
      </div>
      <div class="cont-item">
        <p class="money"><span>￥</span><span>{{flProductPrice}}</span></p>
        <p class="contName">{{flProductName}}</p>
        <p class="contInfo">{{flProductTitle}}</p>

      </div>
      <div class="cont-item cont-item1">
        <p class="freight"><span>运费</span><span>快递配送</span></p>
      </div>
      <div class="cont-item cont-item1">
        <div class="zong">
          <div class="quality">
            <img src="../../assets/image/zheng.png" />
            <span>正品保证</span>
          </div>
          <div class="quality">
            <img src="../../assets/image/you.png" />
            <span>品质严选</span>
          </div>
        </div>

      </div>
      <div class="detail-title">
        <img src="../../assets/image/z.png" />
        <span>商品详情</span>
        <img src="../../assets/image/y.png" />
      </div>
      <div
        class="detail-image"
        v-for="(item,index) in proctImgList"
        :key="index"
      >
        <img :src="item" />
      </div>
    </div>
    <div class="detail-bottom">
      <div class="bot-left">
        <div class="left-bot">
          <img src="../../assets/image/dingdan.png"
           @click="goOrder()"/>
          <div>订单</div>
        </div>
        <div class="left-bot">
          <img
            src="../../assets/image/gouwuche.png"
            @click="goShopCar()"
          />
          <div>购物车</div>
        </div>
      </div>
      <div class="bot-right">
        <div
          class="addGo"

          @click="tobuyOne(flProductId)"
        >加入购物车</div>
        <div class="comBuy" @click="tobuy(flProductId)">立即购买</div>

      </div>
    </div>
    <div
      class="goTop"
      @click="toTop"
    >
      <img src="../../assets/image/shang.png" />
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Axios from 'axios';
  import { mapState, mapActions } from 'vuex';
  import { Swipe, SwipeItem, Toast ,Dialog} from 'vant';

  Vue.use(Dialog);
  Vue.use(Swipe);
  Vue.use(SwipeItem);

  export default {

    data() {
      return {
        flProductId:'',
        flProductHomeImage:'',
        flProductPrice:'',
        flProductTitle:'',
        flProductName:'',
          current: 0,
          proctImgList:[]
      };
    },

    // 生命周期 - 创建完成（访问当前this实例）
    created() {
      if(!this.$route.query.pid){
        var id=localStorage.getItem('sid')
      }else{
        var id=this.$route.query.pid
      }

      this.CorinsuranceshopListDetail({flProductId:id}).then(res=>{
        if(res.code==200){
          this.flProductId=res.data.flProductId;
           this.flProductName=res.data.flProductName;
           this.flProductTitle=res.data.flProductTitle;
           this.flProductPrice=res.data.flProductPrice;
           this.flProductHomeImage=res.data.flProductHomeImage;
           this.proctImgList=res.data.flProductImages.substr(0,res.data.flProductImages.length-1).split(',');

          //  this.proctImgList=res.data.flProductImages.substr(0,res.data.flProductImages.length-1).split(',')
        }else{
          Toast(res.msg)
        }

      })


    },
 // 生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    },
    methods: {
     ...mapActions('CrinsuranceMall', ['CorinsuranceshopList','CorinsuranceshopListDetail']),
     ...mapActions('shopList', ['addCart','checkShop1']),
      ...mapActions('warranty', ['myFlOrder']),
        ...mapActions('shopList', ['getShopList']),
     back(){
        if(localStorage.getItem('detailBack')==0){
            this.$router.push({name:'shopCarList',query:{back:this.$route.query.back}})
          }else if(localStorage.getItem('detailBack')==1){
            this.$router.push({name:'corporateWelfare'})
          }else{
            this.$router.go(-1)
          }
     },
     tobuyOne(id){
    this.addCart({
      pid:id
    }).then(res=>{
      if(res.code==200){
        Toast({
          duration: 500, // 持续展示 toast
          message:'加入购物车成功',
        })
      }else{
        Toast(res.msg)
      }
    })
  },
  tobuy(id){
   this.getShopList().then(res=>{
        if(res.code==200){
     localStorage.setItem('pid',id);
   this.$router.push({name:'orderSure',query:{payType:1}});
        }else{
          Toast(res.msg)
        }
      })


  },
  goOrder(){
   if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null){
          this.myFlOrder().then((res) => {
        if (res.code == 200){
        this.$router.push({name:'myOrder',query:{order:0}})
        }else{
         Toast(res.msg);
        }
      });
    }else{
            Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText:'确定',
          confirmButtonColor:"#5d6d8e",
          showCancelButton:false,
          className:'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          });

        });
        }
  },
  goShopCar(){
   if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null){
        this.getShopList().then(res=>{
        if(res.code==200){
       localStorage.setItem('shopCarBack','0')
        localStorage.setItem('sid',this.$route.query.pid)
        this.$router.push({name:'shopCarList',query:{back:this.$route.query.back}})
        }else{
          Toast(res.msg)
        }
      })

    }else{
            Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText:'确定',
          confirmButtonColor:"#5d6d8e",
          showCancelButton:false,
          className:'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          });

        });
        }
  },
      onChange(index) {
        this.current = index;
      },
      toTop(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    },
  };
</script>
<style scoped>
.goTop img{
  width:0.7rem;
  height:0.7rem;
  display: block;
  position: fixed;
  right:0.2rem;
  bottom:1.8rem
}
.bot-right{
  display: flex;
  justify-content: center;
  align-items: center;
}
.addGo{
	font-family: PingFang-SC-Medium;
	font-size: 0.3rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #ffffff;
  width: 1.9rem;
	background-color: #e5b379;
  padding:0.2rem 0;
  text-align: center;
  border-radius: 0.9rem 0 0 0.9rem;
}
.comBuy{
  	font-family: PingFang-SC-Medium;
	font-size: 0.3rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #ffffff;
  width: 1.9rem;
	background-color: #fc4459;
  padding:0.2rem 0;
  text-align: center;
  border-radius:0 0.9rem 0.9rem 0;

}
.left-bot{
  	font-family: PingFang-SC-Regular;
	font-size: 0.24rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #333333;
  text-align: center;
  padding-top:0.05rem;
  margin-right:0.7rem
}
.left-bot img{
  height:0.36rem;
  display: block;
  padding:0;
  margin:0 auto;
  margin-bottom:0.1rem
}
.this-insurance-detail{
  min-height:100%;
  background: #f1f3f4;

}
.top{
  background: #fff;
}
.cont{
  padding-top:1rem;
  padding-bottom:1.18rem
}
.theSwpper{
  width: 100%;
	height: 6.54rem;
  position: relative;
}
.custom-indicator{
  width: 1.01rem;
	height: 0.44rem;
  line-height: 0.44rem;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 0.22rem 0 0 0.22rem;
	/* opacity: 0.5; */
  	font-family: PingFang-SC-Medium;
	font-size: 0.28rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #ffffff;
  position: absolute;
  right:0;
  bottom:0.5rem;
  text-align: center;
}
.cont-item{
  	width: 100%;
	height: auto;
	background-color: #ffffff;
	box-shadow: 0.02rem -0.1rem 0.09rem 0rem
		rgba(41, 94, 137, 0.03);
    padding-bottom:0.2rem;
    margin-bottom:0.1rem
}
.money{
  font-family: PingFang-SC-Medium;
	font-size: 0.24rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #ef3030;
  padding-left:0.5rem;
  padding-top:0.3rem;
}
.money span:nth-child(2){
font-size: 0.34rem;
}
.contName{
	font-family: PingFang-SC-Medium;
	font-size: 0.3rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #333333;
   padding-left:0.5rem;
  padding-top:0.1rem;
}
.contInfo{
  	font-family: PingFang-SC-Medium;
	font-size: 0.28rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #999999;
  padding-left:0.5rem;
  padding-top:0.1rem;
}
.freight{
  	height: 0.78rem;
  padding:0;
  padding-left:0.5rem;
  display: flex;
  align-items: center;
}
.freight span:nth-child(1){
  	font-family: PingFang-SC-Medium;
	font-size: 0.28rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #999999;
  display: block;
}
.freight span:nth-child(2){
	font-family: PingFang-SC-Medium;
	font-size: 0.28rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #333333;
  margin-left:0.24rem;
  display: block;
}
.quality{
  display: flex;
  align-items: center;
  margin-right:0.7rem
}
.zong{
  display: flex;
  align-items: center;
  padding:0.2rem 0;
  padding-left:0.5rem
}
.quality img{
	width: 0.28rem;
	height: 0.28rem;
  display: block;
  margin-top: 0.04rem;
  margin-right:0.1rem
}
.quality span{
  font-family: PingFang-SC-Medium;
	font-size: 0.26rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #999999;
}
.cont-item1{
  padding-bottom: 0;

}
.detail-title{
  height:0.7rem;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFang-SC-Medium;
	font-size: 0.26rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0rem;
	color: #999999;

}
.detail-title img{
  height:0.1rem;
  flex-shrink: 0;


}
.detail-title span{
  margin:0 0.1rem
}
.detail-image{
  width:100%
}
.detail-image img{
  width:100%;
  display: block;
}
.detail-bottom{
  	width:100%;
	height: 1.18rem;
	background-color: #ffffff;
	box-shadow: 0.02rem -0.1rem 0.09rem 0rem
		rgba(41, 94, 137, 0.03);
  position: fixed;
  bottom:0;
  left:0;
  box-sizing: border-box;
  padding:0 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bot-left{
  display: flex;
  align-items: center;
}
</style>
